// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as Routes_BlogPostCategory from "../../../../routes/common/Routes_BlogPostCategory.res.js";

function BlogPostNavbar(props) {
  var slug = props.slug;
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : 0;
  var slug$1 = slug !== undefined ? slug : "";
  var postsUnderlined = selectedCategory === "BlogPosts" ? Cx.cx([
          DashboardNavbarCss.underlinedLinkContainer,
          DashboardNavbarCss.underlinedLinkItem
        ]) : Cx.cx([
          DashboardNavbarCss.linkContainer,
          DashboardNavbarCss.normalLinkItem
        ]);
  var analyticsUnderlined = selectedCategory === "Analytics" ? Cx.cx([
          DashboardNavbarCss.underlinedLinkContainer,
          DashboardNavbarCss.underlinedLinkItem
        ]) : Cx.cx([
          DashboardNavbarCss.linkContainer,
          DashboardNavbarCss.normalLinkItem
        ]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Categories",
                                  className: DashboardNavbarCss.normalLinkItem,
                                  href: Routes_BlogPostCategory.Dashboard.index
                                }),
                            className: DashboardNavbarCss.linkContainer
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Blog Posts",
                                  href: Routes_BlogPost.Dashboard.index
                                }),
                            className: postsUnderlined
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Analytics",
                                  href: Routes_BlogPost.Dashboard.analytics
                                }),
                            className: analyticsUnderlined
                          }),
                      selectedCategory === "New" ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Link.make, {
                                    href: Routes_BlogPostCategory.Dashboard.$$new,
                                    className: DashboardNavbarCss.linkItem,
                                    children: "New Blog Post"
                                  }),
                              className: Cx.cx([
                                    DashboardNavbarCss.linkContainer,
                                    DashboardNavbarCss.underlinedLinkContainer
                                  ])
                            }) : (
                          selectedCategory === "Edit" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: Routes_BlogPost.Dashboard.edit(id$1),
                                                className: DashboardNavbarCss.linkItem,
                                                children: "Edit"
                                              }),
                                          className: Cx.cx([
                                                DashboardNavbarCss.linkContainer,
                                                DashboardNavbarCss.underlinedLinkContainer
                                              ])
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: Routes_BlogPost.show(slug$1),
                                                className: DashboardNavbarCss.linkItem,
                                                children: "View"
                                              }),
                                          className: DashboardNavbarCss.linkContainer
                                        })
                                  ]
                                }) : null
                        )
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = BlogPostNavbar;

export {
  Css ,
  make ,
}
/* Link Not a pure module */
